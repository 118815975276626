<template>
  <Modal :value="visible"
         title="查看标签"
         footer-hide
         width="540"
         @on-visible-change="onVisibleChange">
    <div class="tagLook">
      <div class="tagLook-title">文件标签：</div>
      <div class="tagLook-list">
        <template v-for="(item,index) in data">
          <span :style="{color:item.fontColor,backgroundColor:item.labelColor}"
                :key="index">{{item.labelName}}</span>
        </template>
      </div>
    </div>
  </Modal>
</template>

<script>
export default {
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    visible: Boolean,
    data: Array
  },
  data () {
    return {

    }
  },
  computed: {},
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },
    // 点击取消
    onClickCancle () {
      this.$emit('change', false)
    },
    // 显示状态改变
    onVisibleChange (boo) {
      if (!boo) this.onClickCancle()
    }
  },
}
</script>

<style lang='scss' scoped>
.tagLook {
  display: flex;
  padding: 16px;
  min-height: 200px;
  .tagLook-title {
    font-size: 16px;
    line-height: 28px;
  }
  .tagLook-list {
    flex: 1;
    span {
      display: inline-block;
      padding: 0 15px;
      border-radius: 4px;
      font-size: 14px;
      line-height: 28px;
      margin-right: 15px;
      margin-bottom: 20px;
    }
  }
}
</style>