<template>
  <div @click="handleClick">
    <input :multiple="multiple"
           :webkitdirectory="webkitdirectory"
           @change="handleChange"
           class="input-style"
           ref="input"
           type="file">
    <slot></slot>
  </div>
</template>

<script>
import bus from '../../utils/bus'
export default {
  props: {
    multiple: {
      type: Boolean,
      default: true
    },
    webkitdirectory: {
      type: Boolean,
      default: false
    },
    folderId: {
      type: Number,
      value: 0
    },
    folderName: String,
    selectFolder: Function
  },
  methods: {
    handleClick () {
      this.$refs.input.click()
    },
    handleChange (e) {
      const files = e.target.files
      if (!files) {
        return
      }
      if (this.selectFolder) {
        this.selectFolder().then(res => {
          bus.$emit('openUploader', {
            files,
            folderId: res.folderId,
            folderName: res.folderName
          })
          this.$refs.input.value = null
        }).catch(err => {
          this.$refs.input.value = null
        })
      } else {
        bus.$emit('openUploader', {
          files,
          folderId: this.folderId,
          folderName: this.folderName
        })
        this.$refs.input.value = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.input-style {
  display: none;
}
</style>
