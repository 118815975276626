<template>
  <Drawer :closable="false"
          :mask="false"
          :mask-closable="false"
          :transfer="false"
          :width="width"
          inner
          scrollables
          v-model="visible">
    <div class="tagAddView">
      <div @click.stop="onClickCancle"
           class="tagAddView-close">> 收起
      </div>
      <div class="tagAddView-con"
           v-if="groupArr.length>0">
        <div class="tagAddView-title">
          <span>添加标签</span>
          <Button @click="onClickConfirm"
                  class="tagAddView-btn"
                  type="primary">确定
          </Button>
        </div>
        <div class="tagAddView-list">
          <div class="tagAddView-left">
            <ul>
              <li :class="{current:currentIndex===index}"
                  :key="index"
                  @click.stop="getTagList(item,index)"
                  v-for="(item,index) in groupArr">{{item.groupName}}
              </li>
            </ul>
          </div>
          <div class="tagAddView-right">
            <Checkbox :disabled="groupArr[currentIndex].children.length===0"
                      :value="isCheckAll"
                      @click.prevent.native="onClickCheckAll">已选中({{groupArr[currentIndex].selectArr.length}})
            </Checkbox>
            <CheckboxGroup v-model="groupArr[currentIndex].selectArr">
              <Checkbox :key="index"
                        :label="item.labelId"
                        class="tagAddView-check"
                        v-for="(item,index) in groupArr[currentIndex].children">
                <span :style="{color:item.fontColor,backgroundColor:item.color}">{{item.labelName}}</span>
              </Checkbox>
            </CheckboxGroup>
          </div>
        </div>
      </div>
    </div>
  </Drawer>
</template>

<script>
import {getGroupList, getTagList} from '../../services/diskapi'

export default {
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    visible: Boolean,
    width: Number
  },
  data () {
    return {
      groupArr: [],
      currentIndex: 0,
    }
  },
  watch: {
    visible: {
      handler (newValue) {
        if (newValue) {
          this.groupArr.map(res => {
            res.selectArr = []
          })
        }
      }
    }
  },
  computed: {
    isCheckAll () {
      let arr = this.groupArr[this.currentIndex].children
      let selectArr = this.groupArr[this.currentIndex].selectArr
      if (arr.length === 0) {
        return false
      } else {
        return arr.length === selectArr.length
      }
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.getGroupList()
    },
    // 获取分组列表
    getGroupList () {
      getGroupList({
        pageNum: 1,
        pageSize: 10000,
        parentId: 0,
        sort: 4,
        mode: 1,
        folderType: 22,
      }).then(res => {
        if (res.code === 0) {
          this.groupArr = res.data.list.map(item => {
            item.groupName = item.name || ''
            item.groupId = item.id
            item.newName = item.name || ''
            item.groupCount = item.documentCount || 0
            item.selectArr = []
            item.children = []
            return item
          })
          let allitem = {
            groupId: 0,
            groupName: '全部',
            selectArr: [],
            children: []
          }
          this.groupArr.splice(0, 0, allitem)
          if (this.groupArr && this.groupArr.length) {
            this.getTagList(this.groupArr[0], 0)
          }
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    },
    // 获取分组下的列表
    getTagList (item, index) {
      this.currentIndex = index
      if (item.children.length > 0) return
      let params = {
        pageNum: 1,
        pageSize: 10000,
        folderId: item.groupId,
        folderType: 22
      }
      getTagList(params).then(res => {
        if (res.code === 0) {
          let list = res.data.list.map(child => {
            child.labelName = child.name
            child.labelId = child.id
            return child
          })
          item.children = list
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    },
    // 全选
    onClickCheckAll () {
      let group = this.groupArr[this.currentIndex]
      if (this.isCheckAll) {
        group.selectArr = []
      } else {
        group.selectArr = group.children.map(item => item.labelId)
      }
    },
    // 点击确定
    onClickConfirm () {
      this.$emit('change', false)
      let arr = []
      this.groupArr.forEach(item => {
        arr = arr.concat(item.selectArr)
      })
      this.$emit('on-click-confirm', arr)
    },
    // 点击取消
    onClickCancle () {
      this.$emit('change', false)
      this.$emit('on-click-cancle')
    },
  },
}
</script>

<style lang='scss' scoped>
  ::v-deep .ivu-drawer {
    top: 64px;
  }

  .tagAddView {
    padding: 4px;
    height: 100%;
    overflow: hidden;

    .tagAddView-close {
      font-size: 14px;
      color: #2d8cf0;
      cursor: pointer;
    }

    .tagAddView-con {
      padding: 1px 30px;
      height: 100%;

      .tagAddView-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        padding-bottom: 30px;
        border-bottom: 1px solid #d8d8d8;

        span {
          font-size: 21px;
        }
      }

      .tagAddView-list {
        display: flex;
        height: 100%;

        .tagAddView-left {
          width: 240px;
          height: 100%;
          border-right: 1px solid #d8d8d8;
          padding-top: 15px;

          li {
            padding: 15px 20px;
            font-size: 16px;
            cursor: pointer;

            &.current {
              color: #2d8cf0;
            }
          }
        }

        .tagAddView-right {
          padding: 30px 0 30px 40px;

          .tagAddView-check {
            margin-top: 30px;
            margin-right: 30px;

            span {
              display: inline-block;
              border-radius: 4px;
              padding: 0 20px;
              font-size: 16px;
              line-height: 32px;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
</style>
