<template>
  <div class='main'
       ref="main">
    <div class="topbar">
      <div class="topbar-left">
        <div class="upload" style="display: none">
          <Dropdown trigger="click">
            <Button class="upload-btn"
                    type="primary">
              <Icon class="m-r-5"
                    custom="i-icon icon-upload"
                    size="16"/>
              上传
            </Button>
            <DropdownMenu slot="list">
              <DropdownItem>
                <DiskUploadComponent :selectFolder="uploadSelectFolder">
                  <div class="uploadSelect-item">
                    <img src="../../assets/common/ic_upload_file.png">
                    <span>文件</span>
                  </div>
                </DiskUploadComponent>
              </DropdownItem>
              <DropdownItem>
                <DiskUploadComponent :selectFolder="uploadSelectFolder"
                                     webkitdirectory>
                  <div class="uploadSelect-item">
                    <img src="../../assets/common/ic_upload_folder.png">
                    <span>文件夹</span>
                  </div>
                </DiskUploadComponent>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
        <ButtonGroup class="m-l-30"
                     v-show="selectArr.length>0">
          <Button class="batchBtn" style="display: none">
            <Icon custom="i-icon icon-share"
                  size="16"/>
            分享
          </Button>
          <Button class="batchBtn" style="display: none">
            <Icon custom="i-icon icon-download"
                  size="16"/>
            下载
          </Button>
          <Button @click.stop="batchDelete"
                  class="batchBtn">
            <Icon custom="i-icon icon-delete"
                  size="16"/>
            删除
          </Button>
          <Button @click.stop="onClickRename"
                  class="batchBtn"
                  style="display: none">
            <!--v-show="selectArr.length===1&&!isSelectGroup"-->
            <Icon custom="i-icon icon-rename"
                  size="16"/>
            重命名
          </Button>
          <Button @click.stop="batchMove"
                  class="batchBtn"
                  style="display: none">
            <Icon custom="i-icon icon-remove"
                  size="16"/>
            移动到
          </Button>
          <Button @click.stop="batchAddTag"
                  class="batchBtn"
                  style="display: none">
            <Icon custom="i-icon icon-tags"
                  size="16"/>
            添加标签
          </Button>
          <Button class="batchBtn" style="display: none">
            <Icon custom="i-icon icon-permission"
                  size="16"/>
            权限
          </Button>
        </ButtonGroup>
      </div>
      <div class="topbar-right">
        <div class="search">
          <input placeholder="搜索文件/文件夹/便签"
                 v-model="search">
          <Icon custom="i-icon icon-search"
                size="14"/>
        </div>
      </div>
    </div>
    <div class="list">
      <div :key="index"
           class="list-item"
           v-for="(item,index) in data">
        <div class="list-title">{{$formatDate(item.date)}}</div>
        <DiskTableComponent :columns="columns"
                            :data="item.groupList"
                            :showHeader="false"
                            @on-rename="onRename"
                            @on-row-dblclick="onRowDblclick"
                            @on-selection-change="onSelectionChange"
                            ref="table">
        </DiskTableComponent>
      </div>
      <infinite-loading :distance="200"
                        :identifier="manualResetState"
                        @infinite="getRecentList"
                        ref="infiniteLoading">
        <div class="total"
             slot="no-more">共{{data.length}}项
        </div>
      </infinite-loading>
    </div>
    <Drawer :closable="false"
            :mask="false"
            :mask-closable="false"
            :transfer="false"
            :width="width"
            inner
            scrollables
            v-model="childVisible">
      <div class="childView">
        <div @click.stop="childVisible=false"
             class="childView-close">> 收起
        </div>
        <div class="childView-con"
             v-if="currentRow">
          <div class="childView-top">
            <div class="childView-top-left">
              <div class="flex a-center">
                <Checkbox :disabled="child.length===0"
                          :value="isCheckChildAll"
                          @on-change="onChangeCheckChildAll"></Checkbox>
                <p>{{currentRow.objName}}</p>
              </div>
              <div class="m-t-10 m-l-25">
                <span class="m-r-20">{{formatTime(currentRow.operateTime)}}</span>
                <span class="m-r-20">{{getOperateType(currentRow.operateType)}}</span>
                <span>{{currentRow.userName}}</span>
              </div>
            </div>
            <ButtonGroup class="m-l-30"
                         v-show="selectChildArr.length>0">
              <Button class="batchBtn" style="display: none">
                <Icon custom="i-icon icon-share"
                      size="16"/>
                分享
              </Button>
              <Button class="batchBtn" style="display: none">
                <Icon custom="i-icon icon-download"
                      size="16"/>
                下载
              </Button>
              <Button @click.stop="batchDeleteChild"
                      class="batchBtn">
                <Icon custom="i-icon icon-delete"
                      size="16"/>
                删除
              </Button>
              <Button @click.stop="onClickChildRename"
                      class="batchBtn"
                      v-show="selectChildArr.length===1">
                <Icon custom="i-icon icon-rename"
                      size="16"/>
                重命名
              </Button>
              <Button @click.stop="batchMoveChild"
                      class="batchBtn">
                <Icon custom="i-icon icon-remove"
                      size="16"/>
                移动到
              </Button>
              <Button @click.stop="batchAddTagChild"
                      class="batchBtn">
                <Icon custom="i-icon icon-tags"
                      size="16"/>
                添加标签
              </Button>
              <Button class="batchBtn">
                <Icon custom="i-icon icon-permission"
                      size="16"/>
                权限
              </Button>
            </ButtonGroup>
          </div>
          <DiskGridComponent :data="child"
                             :showHeader="false"
                             @on-click-addTag="onChildClickAddTag"
                             @on-click-deleteTag="onChildClickDeleteTag"
                             @on-click-lookTag="onChildClickLookTag"
                             @on-rename="onChildRename"
                             @on-row-dblclick="onChildDblclick"
                             @on-selection-change="onSelectionChildChange"
                             ref="grid"></DiskGridComponent>
        </div>
      </div>
    </Drawer>
    <DiskDirectoryComponent :title="directoryTitle"
                            :selectArr="selectArr"
                            @on-click-cancle="cancleSelectFolder"
                            @on-click-confirm="getSelectFolder"
                            v-model="directoryVisible"></DiskDirectoryComponent>
    <TagAddComponent :width="width"
                     @on-click-cancle="cancleSelectAddTag"
                     @on-click-confirm="getSelectAddTag"
                     v-model="addTagVisible"></TagAddComponent>
    <TagLookComponent :data="tagArr"
                      v-model="lookTagVisible"></TagLookComponent>
    <TagDeleteComponent :data="tagArr"
                        @on-click-cancle="cancleSelectDeleteTag"
                        @on-click-confirm="getSelectDeleteTag"
                        v-model="deleteTagVisible"></TagDeleteComponent>
  </div>
</template>

<script>
import {
  cancleRelevanceTag,
  deleteFolderAndFileToRecycle,
  editFileName,
  editFolderName,
  getFolderAndFileTag,
  getRecentList,
  moveFolderAndFile,
  relevanceTag
} from '../../services/diskapi'
import DiskUploadComponent from '../../components/disk/DiskUploadComponent'
import DiskTableComponent from '../../components/disk/DiskTableComponent'
import DiskGridComponent from '../../components/disk/DiskGridComponent'
import DiskTableExpand from '../../components/disk/DiskTableExpand'
import DiskDirectoryComponent from '../../components/disk/DiskDirectoryComponent'
import TagAddComponent from '../../components/disk/TagAddComponent'
import TagLookComponent from '../../components/disk/TagLookComponent'
import TagDeleteComponent from '../../components/disk/TagDeleteComponent'
import bus from '../../utils/bus'

export default {
  components: {
    DiskUploadComponent, DiskTableComponent, DiskGridComponent, DiskTableExpand, DiskDirectoryComponent,
    TagAddComponent, TagLookComponent, TagDeleteComponent
  },
  data () {
    return {
      search: '',
      width: 0,
      columns: [
        {
          type: 'expand',
          width: 1,
          render: (h, params) => {
            return h(DiskTableExpand, {
              props: {
                data: params.row.operationList
              },
              on: {
                click: () => {
                  this.showChild(params.row)
                }
              }
            })
          }
        },
        {
          type: 'selection',
          width: 45,
          align: 'center'
        },
        {
          title: '文件名称',
          key: 'name',
          minWidth: 200,
          tooltip: true,
          slot: 'name'
        },
        {
          title: '修改时间',
          key: 'operateTime',
          width: 150,
          render: (h, params) => {
            let operateTime = params.row.operateTime
            let text = this.formatTime(operateTime)
            return h('span', text)
          }
        },
        {
          title: '操作类型',
          key: 'operateType',
          width: 140,
          render: (h, params) => {
            let operateType = params.row.operateType
            let text = this.getOperateType(operateType)
            return h('span', text)
          }
        },
        {
          title: '文件大小',
          key: 'objSize',
          width: 140,
          render: (h, params) => {
            let size = params.row.objSize
            let text = size ? this.$formatFileSize(size) : '-'
            return h('span', text)
          }
        },
        {
          title: '权限',
          key: '',
          width: 140,
        },
        {
          title: '操作人',
          key: 'userName',
          width: 140,
        },
      ],
      data: [],
      selectArr: [],
      pageNum: 1,
      pageSize: 20,
      isMore: true,
      manualResetState: false,//手动重置触底加载状态
      childVisible: false,
      child: [],
      currentRow: null,
      selectChildArr: [],
      directoryVisible: false,
      directoryTitle: '上传',
      addTagVisible: false,
      lookTagVisible: false,
      tagArr: [],
      deleteTagVisible: false
    }
  },
  mounted () {
    this.init()
  },
  computed: {
    // 选中的是否有组
    isSelectGroup () {
      for (const item of this.selectArr) {
        if (item.operationList.length > 1) {
          return true
        }
      }
      return false
    },
    // child是否全选
    isCheckChildAll () {
      if (this.selectChildArr.length === 0) {
        return false
      } else {
        return this.selectChildArr.length === this.child.length
      }
    },
  },
  methods: {
    init () {
      this.width = this.$refs.main.offsetWidth
      bus.$on('uploadSuccess', this.resetListData)
    },
    // 重置列表数据
    resetListData () {
      this.pageNum = 1
      this.data = []
      this.selectArr = []
      this.isMore = true
      this.manualResetState = !this.manualResetState
    },
    getShowData (list) {
      let resultData = []
      if (list && list.length) {
        let map = new Map()
        list.map(item => {
          let date = new Date(item.operateTime).format('yyyy-MM-dd')
          if (map.has(date)) {
            let items = map.get(date)
            let groupList = items.groupList
            let user = item.user
            groupList.push({
              id: item.id,
              name: item.objectName || '',
              newName: item.objectName || '',
              operateTime: item.operateTime,
              objSize: item.objectSize || '',
              userName: user.name || '',
              objType: item.objectType,
              isRename: false,
              isCheck: false,
              _expanded: false,
              parentId: item.parentId || 0,
              operationList: [],
              objUrl: item.objectUrl || ''
            })
          } else {
            let user = item.user
            let items = {
              date: date,
              groupList: [{
                id: item.id,
                name: item.objectName || '',
                newName: item.objectName || '',
                operateTime: item.operateTime,
                objSize: item.objectSize || '',
                userName: user.name || '',
                objType: item.objectType,
                isRename: false,
                isCheck: false,
                _expanded: false,
                parentId: item.parentId || 0,
                operationList: [],
                objUrl: item.objectUrl || ''
              }]
            }
            map.set(date, items)
          }
        })
        for (let item of map.values()) {
          resultData.push(item)
        }
      }
      return resultData
    },
    // 获取列表
    getRecentList ($state) {
      if (!this.isMore) return
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        folderType: 1
      }
      getRecentList(params).then(res => {
        if (res.code === 0) {
          let list = res.data.list
          if (list.length === 0) {
            this.isMore = false
            $state.complete()
            return
          }
          if (this.pageNum < 2) {
            this.data = this.getShowData(list)
          } else {
            let end = this.data[this.data.length - 1]
            let templist = this.getShowData(list)
            if (templist && templist.length) {
              if (templist[0].date === end.date) {
                end.groupList = end.groupList.concat(templist[0].groupList)
                this.data = this.data.concat(templist.slice(1))
              } else {
                this.data = this.data.concat(templist)
              }
            }
          }
          if (params.pageNum >= res.data.pages) {
            this.isMore = false
            $state.complete()
          } else {
            this.pageNum++
          }
          $state.loaded()
        } else {
          if (res.msg) this.$Message.info(res.msg)
          $state.complete()
        }
      })
    },
    // 格式化时间
    formatTime (times) {
      let date = times.split(' ')[0]
      let time = times.split(' ')[1]
      let text = this.$formatDate(date) + ' ' + time
      return text
    },
    // 操作类型
    getOperateType (type) {
      let text = ''
      switch (type) {
        case 1:
          text = '网页上传'
          break
        case 2:
          text = '小程序上传'
          break
        case 3:
          text = '重命名'
          break
        case 4:
          text = '新建'
          break
        case 5:
          text = '移动'
          break
        case 6:
          text = '从回收站中还原'
          break
        default:
          break
      }
      return text
    },
    // 选择改变
    onSelectionChange (selection) {
      this.selectArr = selection
    },
    // child选择改变
    onSelectionChildChange (selection) {
      this.selectChildArr = selection
    },
    // 展示child详情
    showChild (row) {
      this.child = row.operationList.map(item => {
        return {
          fileId: item.objId,
          name: item.objName,
          newName: item.objName,
          url: item.objUrl,
          size: item.objSize,
          folderId: item.parentId,
          folderName: item.parentName,
          objType: 1,
          isRename: false,
          isCheck: false
        }
      })
      this.currentRow = row
      this.childVisible = true
    },
    // 修改child全选
    onChangeCheckChildAll (boo) {
      this.$refs.grid.onChangeCheckAll(boo)
    },
    // 双击某一行
    onRowDblclick (row) {
      if (row.operationList.length > 1) {
        this.showChild(row)
      } else {
        if (row.objType === 1) {
          let url = 'http://47.104.70.223:8012/onlinePreview?url=' + encodeURIComponent(this.$BaseSix.encode(row.objUrl))
          window.open(url)
        } else if (row.objType === 2) {
          let params = {
            id: row.id,
            name: row.name,
            parentId: row.parentId
          }
          this.$router.push({
            path: '/disk/' + this.$Base64.encode(JSON.stringify(params))
          })
        }
      }
    },
    // 双击child某一行
    onChildDblclick (row) {
      let url = 'http://47.104.70.223:8012/onlinePreview?url=' + encodeURIComponent(this.$BaseSix.encode(row.url))
      window.open(url)
    },
    // 点击重命名
    onClickRename () {
      let selectItem = this.selectArr[0]
      let item
      let index
      let indexs
      let objType = selectItem.objType
      for (const i in this.data) {
        let dataItem = this.data[i]
        if (selectItem.date === dataItem.date) {
          index = i
          for (const j in dataItem.groupList) {
            let dataItems = dataItem.groupList[j]
            if (selectItem.id === dataItems.id) {
              item = dataItems
              indexs = j
              break
            }
          }
          break
        }
      }
      this.$refs.table[index].onClickRename(item, indexs)
    },
    // 点击child重命名
    onClickChildRename () {
      let selectItem = this.selectChildArr[0]
      let item
      let index
      for (const i in this.child) {
        let dataItem = this.child[i]
        if (selectItem.objId === dataItem.objId) {
          item = dataItem
          index = i
          break
        }
      }
      this.$refs.grid.onClickRename(item, index)
    },
    // 重命名
    onRename (item) {
      if (item.objType === 1) {
        let params = {
          fileId: item.operationList[0].objId,
          fileName: item.newName
        }
        editFileName(params).then(res => {
          item.isRename = false
          if (res.code === 0) {
            item.name = res.data.name
          } else {
            item.newName = item.name
            if (res.msg) this.$Message.info(res.msg)
          }
        })
      } else {
        let params = {
          folderId: item.operationList[0].objId,
          folderName: item.newName
        }
        editFolderName(params).then(res => {
          item.isRename = false
          if (res.code === 0) {
            item.name = res.data.name
          } else {
            item.newName = item.name
            if (res.msg) this.$Message.info(res.msg)
          }
        })
      }
    },
    // child重命名
    onChildRename (item) {
      let params = {
        fileId: item.fileId,
        fileName: item.newName
      }
      editFileName(params).then(res => {
        item.isRename = false
        if (res.code === 0) {
          item.name = res.data.name
        } else {
          item.newName = item.name
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    },
    // 点击批量删除
    batchDelete () {
      let arr = []
      arr = this.selectArr
      // this.selectArr.forEach(item => {
      //   item.operationList.forEach(items => {
      //     arr.push(items)
      //   })
      // })
      this.onDelete(arr)
    },
    // 点击批量删除child
    batchDeleteChild () {
      this.onDelete(this.selectChildArr)
    },
    // 批量删除文件夹与文件进回收站
    onDelete (list) {
      this.$Modal.confirm({
        title: '删除',
        content: '<p>删除后将移入回收站</p>',
        onOk: () => {
          let folderIdList = []
          let idList = []
          list.forEach(item => {
            if (item.objType === 1) {
              idList.push(item.id)
            } else {
              folderIdList.push(item.id)
            }
          })
          let olderIds = folderIdList.join(',')
          let ids = idList.join(',')
          deleteFolderAndFileToRecycle({
            folderIdList: olderIds,
            idList: ids
          }).then(res => {
            if (res.code === 0) {
              this.$Message.info('删除成功!')
              this.resetListData()
            } else {
              if (res.msg) this.$Message.info(res.msg)
            }
          })
        }
      })
    },
    // 上传选择文件夹回调
    uploadSelectFolder () {
      this.directoryVisible = true
      this.directoryTitle = '上传'
      return new Promise((resolve, reject) => {
        this.$once('getUploadSelectFolder', (query) => {
          resolve(query)
        })
        this.$once('cancleUploadSelectFolder', () => {
          reject()
        })
      })
    },
    // 获取选择的文件夹
    getSelectFolder (obj) {
      if (this.directoryTitle === '上传') {
        this.$emit('getUploadSelectFolder', obj)
      } else if (this.directoryTitle === '移动') {
        this.$emit('getMoveSelectFolder', obj)
      }
    },
    // 取消选择文件夹
    cancleSelectFolder () {
      if (this.directoryTitle === '上传') {
        this.$emit('cancleUploadSelectFolder')
      } else if (this.directoryTitle === '移动') {
        this.$off('getMoveSelectFolder')
      }
    },
    // 点击批量移动
    batchMove () {
      this.directoryVisible = true
      this.directoryTitle = '移动'
      this.$once('getMoveSelectFolder', (query) => {
        let arr = []
        this.selectArr.forEach(item => {
          item.operationList.forEach(items => {
            arr.push(items)
          })
        })
        this.onMove(query, arr)
      })
    },
    // 点击批量移动child
    batchMoveChild () {
      this.directoryVisible = true
      this.directoryTitle = '移动'
      this.$once('getMoveSelectFolder', (query) => {
        this.onMove(query, this.selectChildArr)
      })
    },
    // 移动
    onMove (query, arr) {
      let folderList = []
      let fileList = []
      arr.forEach(item => {
        if (item.objType === 1) {
          fileList.push({
            fileId: item.objId || item.fileId,
            fileName: item.name || item.objName
          })
        } else {
          folderList.push({
            folderId: item.objId || item.folderId,
            folderName: item.name || item.objName
          })
        }
      })
      let params = {
        folderId: query.folderId,
        folderList,
        fileList
      }
      moveFolderAndFile(params).then(res => {
        if (res.code === 0) {
          this.$Message.info('移动成功!')
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    },
    // 获取选择的添加标签
    getSelectAddTag (obj) {
      this.$emit('getSelectAddTagArr', obj)
    },
    // 取消选择添加标签
    cancleSelectAddTag () {
      this.$off('getSelectAddTagArr')
    },
    // 点击添加标签child
    onChildClickAddTag (row) {
      this.addTagVisible = true
      this.$once('getSelectAddTagArr', (query) => {
        this.relevanceTag(query, [row])
      })
    },
    // 点击批量添加标签
    batchAddTag () {
      this.addTagVisible = true
      this.$once('getSelectAddTagArr', (query) => {
        let arr = []
        this.selectArr.forEach(item => {
          item.operationList.forEach(items => {
            arr.push(items)
          })
        })
        console.log(arr)
        this.relevanceTag(query, arr)
      })
    },
    // 点击批量添加标签Child
    batchAddTagChild () {
      this.addTagVisible = true
      this.$once('getSelectAddTagArr', (query) => {
        this.relevanceTag(query, this.selectChildArr)
      })
    },
    // 关联标签
    relevanceTag (query, arr) {
      let folderList = []
      let fileList = []
      arr.forEach(item => {
        if (item.objType === 1) {
          fileList.push({
            fileId: item.objId || item.fileId,
            fileName: item.name || item.objName
          })
        } else {
          folderList.push({
            folderId: item.objId || item.folderId,
            folderName: item.name || item.objName
          })
        }
      })
      let params = {
        folderList,
        fileList,
        labelList: query.map(item => {
          return {
            labelId: item
          }
        })
      }
      relevanceTag(params).then(res => {
        if (res.code === 0) {
          this.$Message.info('添加标签成功!')
          this.resetListData()
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    },
    // 点击查看标签
    onChildClickLookTag (row) {
      let params = {
        objId: row.folderId || row.fileId,
        objType: row.objType
      }
      getFolderAndFileTag(params).then(res => {
        if (res.code === 0) {
          this.tagArr = res.data
          this.lookTagVisible = true
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    },
    // 获取选择的删除标签
    getSelectDeleteTag (obj) {
      this.$emit('getSelectDeleteTagArr', obj)
    },
    // 取消选择删除标签
    cancleSelectDeleteTag () {
      this.$off('getSelectDeleteTagArr')
    },
    // 点击删除标签
    onChildClickDeleteTag (row) {
      let params = {
        objId: row.folderId || row.fileId,
        objType: row.objType
      }
      getFolderAndFileTag(params).then(res => {
        if (res.code === 0) {
          this.tagArr = res.data
          this.deleteTagVisible = true
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
      this.$once('getSelectDeleteTagArr', (query) => {
        this.cancleRelevanceTag(query, row)
      })
    },
    // 取消关联标签
    cancleRelevanceTag (query, row) {
      let params = {
        labelIdList: query.join()
      }
      if (row.objType === 1) {
        params.fileIdList = row.fileId
      } else {
        params.folderIdList = row.folderId
      }
      cancleRelevanceTag(params).then(res => {
        if (res.code === 0) {
          this.$Message.info('删除标签成功!')
          this.resetListData()
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    },
  }
}

</script>
<style lang='scss' scoped>
  .main {
    width: 100%;
    height: 100%;
    padding-left: 30px;

    .topbar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 50px 0 20px;

      .topbar-left {
        display: flex;
        align-items: center;

        .upload {
          position: relative;

          .upload-btn {
            width: 98px;
            height: 34px;
          }

          .uploadSelect-item {
            display: flex;
            align-items: center;

            img {
              width: 13px;
              height: 13px;
              margin-right: 10px;
            }

            span {
              font-size: 14px;
              color: #09aaff;
            }
          }
        }

        .newFolder {
          width: 130px;
          height: 34px;
        }

        .batchBtn {
          width: 90px;
          height: 34px;
        }
      }

      .topbar-right {
        display: flex;
        align-items: center;

        .search {
          display: flex;
          align-items: center;
          width: 360px;
          height: 34px;
          padding: 0 15px;
          background-color: #ededed;
          border-radius: 17px;

          input {
            font-size: 14px;
            flex: 1;
          }

          .i-icon {
            margin-left: 10px;
          }
        }

        .listType {
          display: flex;
          align-items: center;
          margin-left: 10px;

          img {
            width: 20px;
            height: 20px;
            margin-left: 10px;
            cursor: pointer;
          }
        }
      }

      ::v-deep .ivu-btn {
        padding: 0;
      }
    }

    .list {
      height: 100%;
      overflow-y: scroll;
      padding-left: 10px;
      margin-top: 30px;

      .list-item {
        margin-bottom: 30px;

        .list-title {
          font-size: 20px;
          line-height: 1;
          padding: 15px;
          font-weight: 600;
          border-bottom: 1px solid #e8eaec;
        }
      }
    }

    ::v-deep .ivu-drawer {
      top: 64px;
    }

    .childView {
      padding: 4px;

      .childView-close {
        font-size: 14px;
        color: #2d8cf0;
        cursor: pointer;
      }

      .childView-con {
        padding: 30px;

        .childView-top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-bottom: 10px;
          border-bottom: 1px solid #d8d8d8;

          .childView-top-left {
            p {
              font-size: 21px;
            }

            span {
              font-size: 16px;
              color: #666;
            }
          }
        }
      }

      ::v-deep .ivu-btn {
        padding: 0;
      }
    }

    .batchBtn {
      width: 90px;
      height: 34px;
    }
  }
</style>
