<template>
  <Modal :value="visible"
         title="删除标签"
         footer-hide
         width="540"
         @on-visible-change="onVisibleChange">
    <div class="tagDelete">
      <Checkbox :value="isCheckAll"
                :disabled="data.length===0"
                @click.prevent.native="onClickCheckAll">已选中({{selectArr.length}})</Checkbox>
      <CheckboxGroup v-model="selectArr"
                     class="tagDelete-list">
        <Checkbox :label="item.labelId"
                  v-for="(item,index) in data"
                  :key="index"
                  class="tagDelete-check">
          <span :style="{color:item.fontColor,backgroundColor:item.labelColor}">{{item.labelName}}</span>
        </Checkbox>
      </CheckboxGroup>
      <div class="tagDelete-sub">
        <Button type="primary"
                class="tagDelete-btn m-r-20"
                @click="onClickConfirm">确定</Button>
        <Button class="tagDelete-btn"
                @click="onClickCancle">取消</Button>
      </div>
    </div>
  </Modal>
</template>

<script>
export default {
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    visible: Boolean,
    data: Array
  },
  data () {
    return {
      selectArr: []
    }
  },
  computed: {
    isCheckAll () {
      if (this.data.length === 0) {
        return false
      } else {
        return this.data.length === this.selectArr.length
      }
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },
    // 全选
    onClickCheckAll () {
      if (this.isCheckAll) {
        this.selectArr = []
      } else {
        this.selectArr = this.data.map(item => item.labelId)
      }
    },
    // 点击确定
    onClickConfirm () {
      this.$emit('change', false)
      this.$emit('on-click-confirm', this.selectArr)
      this.selectArr = []
    },
    // 点击取消
    onClickCancle () {
      this.$emit('change', false)
      this.$emit('on-click-cancle')
    },
    // 显示状态改变
    onVisibleChange (boo) {
      if (!boo) this.onClickCancle()
    }
  },
}
</script>

<style lang='scss' scoped>
.tagDelete {
  padding: 16px;
  .tagDelete-list {
    min-height: 160px;
    .tagDelete-check {
      margin-top: 20px;
      margin-right: 30px;
      span {
        display: inline-block;
        border-radius: 4px;
        padding: 0 15px;
        font-size: 14px;
        line-height: 28px;
        margin-left: 10px;
      }
    }
  }
  .tagDelete-sub {
    display: flex;
    align-items: center;
    justify-content: center;
    .tagDelete-btn {
      width: 80px;
    }
  }
}
</style>