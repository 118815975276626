<template>
  <div class="flex"
       @click.stop="onClick">
    <template v-for="(item,index) in data">
      <div :key="index"
           class="item"
           v-if="index<count">
        <div class="item-img">
          <FileIcon :file="{name:item.objName,objType:item.objType,url:item.objUrl}"
                    size="big"
                    class="flex a-center" />
        </div>
        <p class="line2"
           :title="item.objName">{{item.objName}}</p>
      </div>
    </template>
    <div class="item"
         v-if="data.length>count">
      <div class="item-img">
        <FileIcon :file="{name:data[count].objName,objType:data[count].objType,url:data[count].objUrl}"
                  size="big"
                  class="flex a-center" />
        <div class="mask">+{{data.length-count}}</div>
      </div>
      <p class="line2"
         :title="data[count].objName">{{data[count].objName}}</p>
    </div>
  </div>
</template>

<script>
import FileIcon from '../../components/common/FileIcon'
export default {
  props: {
    data: Array
  },
  components: { FileIcon },
  data () {
    return {
      count: 6
    }
  },
  computed: {},
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },
    onClick () {
      this.$emit('click')
    }
  }
}

</script>
<style lang='scss' scoped>
.item {
  width: 150px;
  padding: 10px;
  margin-right: 10px;
  cursor: pointer;
  .item-img {
    width: 126px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  p {
    font-size: 14px;
    color: #424e67;
    text-align: center;
    word-break: break-all;
    margin-top: 5px;
  }
  .mask {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 36px;
    color: #fff;
  }
}
</style>